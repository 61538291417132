@font-face {
  font-family: 'ArialRoundedMt';
  src: local(ArialRoundedMt), url(fonts/arial_rounded_bold.ttf);
}

@font-face {
  font-family: 'ARLRDBD';
  src: local(ARLRDBD), url(fonts/ARLRDBD.ttf);
}

@font-face {
  font-family: 'Verlag';
  src: url('fonts/Verlag-XLight.otf') format('opentype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Verlag';
  src: url('fonts/Verlag-XLightItalic.otf') format('opentype');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Verlag';
  src: url('fonts/Verlag-LightItalic.otf') format('opentype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Verlag';
  src: url('fonts/Verlag-Light.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Verlag';
  src: url('fonts/Verlag-Book.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Verlag';
  src: url('fonts/Verlag-Bold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Verlag';
  src: url('fonts/Verlag-BoldItalic.otf') format('opentype');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Verlag';
  src: url('fonts/Verlag-Black.otf') format('opentype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Verlag';
  src: url('fonts/Verlag-BlackItalic.otf') format('opentype');
  font-weight: 900;
  font-style: italic;
}
